var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","flat":"","height":"72"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),(_vm.$store.state.auth.includes('01061') || _vm.$store.state.isadmin)?_c('default-search'):_vm._e(),_c('v-btn',{attrs:{"text":"","to":"/dashboard"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('default-notifications'),_c('default-account'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.toggle_dark_mode}},[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isEndpointAvailable)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-network")]):_vm._e()]}}])},[_c('span',[_vm._v("Connection OK")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isEndpointAvailable)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-network-off")]):_vm._e()]}}])},[_c('span',[_vm._v("No Connection")])]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.logout(1)}}},[_c('v-icon',[_vm._v("mdi-logout")])],1)],1),_c('v-navigation-drawer',{attrs:{"app":"","width":"300"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"px-2 mt-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require('../assets/Mpact.gif')}})],1),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.myName1)),_c('strong',[_vm._v(_vm._s(_vm.myName2)+" ")])])],1),_c('v-list-item'),_c('v-list',{attrs:{"nav":""}},[_vm._l((_vm.menulist),function(item){return [(item.children)?_c('v-list-group',{key:item.text,attrs:{"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]},proxy:true}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}},[_vm._l((item.children),function(child){return [(child.children)?_c('v-list-group',{key:child.text,attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.text)}})],1),_c('v-list-item-action',[_c('v-icon',{domProps:{"textContent":_vm._s(child.icon)}})],1)]},proxy:true}],null,true),model:{value:(child.model),callback:function ($$v) {_vm.$set(child, "model", $$v)},expression:"child.model"}},_vm._l((child.children),function(child2){return _c('v-list-item',{key:child2.text,attrs:{"to":_vm.menus_auth.includes(child2.code) || _vm.$store.state.isadmin
                    ? child2.to
                    : '/auth'}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child2.text)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(child2.icon)}})],1)],1)}),1):_c('v-list-item',{key:child.text,attrs:{"to":_vm.menus_auth.includes(child.code) || _vm.$store.state.isadmin
                  ? child.to
                  : '/auth'},model:{value:(_vm.menu_select),callback:function ($$v) {_vm.menu_select=$$v},expression:"menu_select"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.text)}})],1),(child.icon)?_c('v-list-item-action',[_c('v-icon',{domProps:{"textContent":_vm._s(child.icon)}})],1):_vm._e()],1)]})],2):_c('v-list-item',{key:item.text,attrs:{"to":item.to},model:{value:(_vm.menu_select),callback:function ($$v) {_vm.menu_select=$$v},expression:"menu_select"}},[_c('v-list-item-action',[(item.icon.startsWith('mdi-'))?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)]})],2)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{attrs:{"padless":""}},[_c('v-spacer'),_c('span',[_vm._v(" "+_vm._s("ESSALAM v 1.0.0 ")+" "),_vm._v(" Copyright © 2024 ")]),_c('v-spacer'),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1),[_c('confirmdialog',{ref:"confirm"})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }