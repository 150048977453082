<template>
  <v-app>
    <v-app-bar app flat height="72">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer> </v-spacer>

      <default-search
        v-if="$store.state.auth.includes('01061') || $store.state.isadmin"
      />

      <v-btn text to="/dashboard">
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <default-notifications />

      <default-account />
      <v-btn text @click="toggle_dark_mode">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <!-- <v-switch
        v-model="dark"
        class="pa-0 mt-6"
        @change="changetheme(dark)"
        dense
        flat
        inset
      >
      </v-switch> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="green"
            v-if="isEndpointAvailable"
            v-bind="attrs"
            v-on="on"
            >mdi-network</v-icon
          >
        </template>
        <span>Connection OK</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="red"
            v-if="!isEndpointAvailable"
            v-bind="attrs"
            v-on="on"
            >mdi-network-off</v-icon
          >
        </template>
        <span>No Connection</span>
      </v-tooltip>
      <v-btn text @click="logout(1)">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app width="300">
      <v-list-item class="px-2 mt-2">
        <v-list-item-avatar>
          <v-img :src="require('../assets/Mpact.gif')"></v-img>
        </v-list-item-avatar>
        <v-list-item>
          {{ myName1 }}<strong>{{ myName2 }} </strong>
        </v-list-item>
      </v-list-item>

      <v-list-item></v-list-item>
      <v-list nav>
        <template v-for="item in menulist">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"> </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="child in item.children">
              <v-list-group
                no-action
                sub-group
                v-model="child.model"
                :key="child.text"
                v-if="child.children"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="child.text"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-text="child.icon"></v-icon>
                  </v-list-item-action>
                </template>

                <v-list-item
                  v-for="child2 in child.children"
                  :key="child2.text"
                  :to="
                    menus_auth.includes(child2.code) || $store.state.isadmin
                      ? child2.to
                      : '/auth'
                  "
                >
                  <v-list-item-title v-text="child2.text"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="child2.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                :key="child.text"
                :to="
                  menus_auth.includes(child.code) || $store.state.isadmin
                    ? child.to
                    : '/auth'
                "
                v-model="menu_select"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.text"> </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="child.icon">
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            v-else
            :key="item.text"
            :to="item.to"
            v-model="menu_select"
          >
            <v-list-item-action>
              <v-icon v-if="item.icon.startsWith('mdi-')">{{
                item.icon
              }}</v-icon>
              <!-- Render custom ICO icon -->
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless>
      <v-spacer></v-spacer>
      <span>
        {{ "ESSALAM v 1.0.0 " }}

        <!-- <strong>
          {{ myname }}
        </strong> -->
        Copyright © 2024
      </span>
      <v-spacer></v-spacer>

      <!-- <strong class="mt-1 mx-8">{{ localTime }}</strong> -->

      <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        top
        :color="snackbar_color"
      >
        {{ snackbar_text }}
      </v-snackbar>
    </v-footer>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-app>
</template>

<script>
import { onLogout, getexpiredate } from "../vue-apollo.js";
import { myLogo, myName, myName1, myName2 } from "print/data.js";
import { menus } from "@/utils/menu.js";
import LOGOUT from "../graphql/LOGOUT.gql";
//import mapsIcon from "@/icons/maps.ico";
//import GETDATE from "../graphql/GETDATE.gql";

const DARK = "darktheme";

export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "../components/Widgets/Account.vue"
      ),

    DefaultNotifications: () =>
      import(
        /* webpackChunkName: "default-notifications" */
        "../components/Widgets/Notifications.vue"
      ),
    DefaultSearch: () =>
      import(
        /* webpackChunkName: "default-search" */
        "../components/Widgets/Search.vue"
      ),
  },

  data: () => ({
    isEndpointAvailable: true,
    messages: 3,
    menu_select: "",
    menus_auth: [],
    title: "",
    dark: false,
    //maps: mapsIcon,
    time: 10000,
    snackbar: false,
    snackbar_text: "",
    snackbar_color: "success",
    snackbar_timeout: 2000,
    drawer: null,
    mini: true,
    global_search: null,
    selectedItem: 0,
    localTime: " ",
    connectTime: " ",
    items: [],
    menulist: [],
    fields_list: [],
    field_id: [],
    menu: "0",
    cs: 1500,
  }),
  // apollo: {
  //   // Query with parameters
  //   getdate: {
  //     query: GETDATE,
  //     // Additional options here
  //     fetchPolicy: "network-only",
  //     pollInterval: 2000,
  //     result({ loading, networkStatus }) {
  //       let oldstatut = this.isEndpointAvailable;
  //       if (!loading) this.isEndpointAvailable = networkStatus == 7;
  //       if (oldstatut && !this.isEndpointAvailable) {
  //         this.snackbar_text = "Connection lost!";
  //         this.snackbar_color = "error";
  //         this.snackbar = true;
  //       }
  //       if (!oldstatut && this.isEndpointAvailable) {
  //         this.snackbar_text = "Connection recovred";
  //         this.snackbar_color = "success";
  //         this.snackbar = true;
  //       }
  //     },
  //   },
  // },
  mounted() {
    this.items = menus;
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },

  async created() {
    // this.checkEndpointStatus();
    await this.initialize();
    this.$store.state.auth.forEach((element) => {
      if (element.substring(1, 2) == 1) {
        const i = this.menus_auth.findIndex(
          (elm) => elm == element.substring(2)
        );
        if (i == -1) this.menus_auth.push(element.substring(2));
      }
    });
    this.updatetitle();
    this.showLocaleTime();
    this.connectTime = new Date().toLocaleString();

    this.menulist = this.childrensItem();

    this.cs++;
  },
  watch: {
    $route() {
      this.updatetitle();
    },
    "$store.state.idleVue.isIdle": {
      handler() {
        if (this.$store.state.idleVue.isIdle) {
          this.showDisconnectTime();
          this.snackbar_timeout = 10000;
          this.snackbar_color = "warning";
        } else {
          this.snackbar = false;
          this.time = 10000;
          this.snackbar_timeout = 2000;
        }
      },
      deep: true,
    },
  },

  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },

    mylogo() {
      return myLogo;
    },
    myname() {
      return myName;
    },
    myName1() {
      return myName1;
    },
    myName2() {
      return myName2;
    },
  },

  methods: {
    checkEndpointStatus() {
      //this.$apollo.queries.getdate;
    },
    field_change() {
      this.$store.dispatch("Fields", this.field_id);
    },
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    redirect() {
      this.$router.push("/");
      clearInterval();
    },
    showDisconnectTime: function () {
      this.time = 10000;
      this.snackbar_text =
        "10 minutes of inactivity, logout in " + this.time / 1000 + " seconds";
      this.snackbar = true;
      let timerId = setInterval(() => {
        this.time -= 1000;
        this.snackbar_text =
          "10 minutes of inactivity, logout in " +
          this.time / 1000 +
          " seconds";
        if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
        if (this.time < 1) {
          clearInterval(timerId);
          // Your logout function should be over here
          this.logout(2);
        }
      }, 1000);
    },
    showLocaleTime: function () {
      var time = this;
      setInterval(function () {
        time.localTime = new Date().toLocaleTimeString();
        // soit router soit renouvler
        if (time.$route.path != "/") {
          if (getexpiredate() < new Date()) {
            clearInterval();
            time.$router.push("/");
          }
        }
      }, 5000);
    },

    updatetitle() {
      let i = this.items[0].children.findIndex(
        (elm) => elm.to == this.$route.path
      );
      if (i >= 0) this.title = this.items[0].children[i].title;
      else {
        let ok = false;
        for (let index = 0; index < this.items[0].children.length; index++) {
          let element = this.items[0].children[index];
          if (ok) break;
          if (element.children) {
            for (let j = 0; j < element.children.length; j++) {
              const element2 = element.children[j];
              if (ok) break;
              if (element2.children) {
                i = element2.children.findIndex(
                  (elm) => elm.to == this.$route.path
                );
                if (i >= 0) {
                  this.title = element2.children[i].title;
                  ok = true;
                  break;
                }
              } else {
                if (element2.to == this.$route.path) {
                  this.title = element2.title;
                  ok = true;
                  break;
                }
              }
            }
          }
        }
      }
    },

    changetheme(value) {
      if (value) localStorage.setItem(DARK, "1");
      else localStorage.setItem(DARK, "0");
      this.$vuetify.theme.dark = value;
    },
    childrensItem() {
      let list = [];
      let l;
      let list2 = this.items[0].children.filter((elm) => elm.hiden != true);
      if (this.$store.state.isadmin) {
        list = list2;
      } else {
        for (let index = 0; index < list2.length; index++) {
          if (list2[index].children) {
            const element = list2[index].children.filter(
              (elm) => this.menus_auth.includes(elm.code) || elm.children
            );
            if (element.length) {
              l = Object.assign({}, list2[index]);
              l.children = [];
              element.forEach((elm5) => {
                //traitement sub group
                if (elm5.children) {
                  const elm2 = elm5.children.filter((elm) =>
                    this.menus_auth.includes(elm.code)
                  );
                  if (elm2.length) {
                    let l2 = Object.assign({}, elm5);
                    l2.children = [];
                    elm2.forEach((elm4) => {
                      l2.children.push(elm4);
                    });
                    l.children.push(l2);
                  }
                } else l.children.push(elm5);
              });
              if (l.children.length > 0) list.push(l);
            }
          } else if (
            this.menus_auth.includes(list2[index].code) ||
            !list2[index].code
          )
            list.push(list2[index]);
        }
      }
      return list;
    },
    initialize() {
      if (localStorage.getItem(DARK) != null) {
        const dark1 = localStorage.getItem(DARK);
        if (dark1 == "1") this.dark = true;
        else this.dark = false;
      }
      this.$vuetify.theme.dark = this.dark;
    },

    set_search() {
      this.$store.dispatch("set_search_text", this.global_search);
    },

    async logout(c) {
      let ok = true;
      if (this.$store.state.isEditing && c == 1) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Document Ouvert !! Etês-vous sûr ?",
            { color: "orange darken-3" }
          )
        ) {
          ok = true;
        }
      }

      if (ok) {
        await this.$maj(LOGOUT);
        onLogout(this.$apollo.provider.defaultClient);
        this.$router.push("/");
      }
    },
  },
};
</script>
<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
