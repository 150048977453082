export const menus= [
    {
      text: "Compagnie",
      icon: "mdi-view-dashboard",
      to: "/dashcommerce",
      menu: "0",
      model: true,
      children: [
        {
          text: "Dashboard",      
          icon: "mdi-view-dashboard",
          to: "/dashboard",
          
        },
        {
          text: "Operateurs",
          icon: "mdi-card-account-details-outline",
          code: "002",
          to: "/oprs",
          title:"Liste des Operateurs",
          model: false,
        },
        {
          text: "Transactions",
          icon: "mdi-transfer",
          code: "003",
           to: "/transactions",
          title:"Liste des Transactions",
          model: false,
        },
        {
          text: "Intervenants",
          icon: "mdi-account-group-outline",
          model: false,
          to: "/agents",
          title:"Liste Intervenants",
          code: "004" 
        },
        {
          text: "Credits",
          icon: "mdi-cash-multiple",
          model: false,
          to: "/credits",
          title:"Liste Credits",
          code: "005" 
        },
        {
          text: "Echéances",
          icon: "mdi-cash-refund",
          model: false,
          to: "/echeances",
          title:"Liste des Echéances",
          code: "006" 
        },
        {
          text: "Ventes",
          icon: "mdi-check-decagram",
          code: "007",
          model: false,
          to: "/ventes",
          hiden: false,
        },
        {
          text: "Paiements ",
          icon: "mdi-cart-arrow-right",
          code: "008",
          to: "/paiements",
          model: false,
        },
      
        {
          text: "Administration ",
          icon: "mdi-office-building-cog",
          children: [
            {
              text: "Users",
              icon: "mdi-file-table-box-multiple",
              to: "/users",
              code: "010",
            },
           
        ]
		},

      ],
    },
  ]