<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'echeances'"
          :AllResponse="'allecheances'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Echeances'"
          :sheetname="'Echeances'"
          :showedit="false"
          :TypeScope="1"
          :del_disable="echeance.statut_id > 1"
          @rowselect="EcheanceChange"
          @open="EcheanceOpen"
          @hrf="EcheanceOpen"
          :Add="false"
          :del="false"
          :key="klist"
          :search_elm="filtre"
          :load="loading"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <echeanceform
      :item="echeance"
      :showForm="!isEcheanceClosed"
      :users="users"
      @close="isEcheanceClosed = true"
      @validate="echeance_valid"
      :key="ktf"
      :modify="(echeance.credit_statut_id == 1 || echeance.id < 0) && editer"
    >
    </echeanceform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
import ECHEANCES from "../graphql/Credits/ECHEANCES.gql";
import ALLECHEANCES from "../graphql/Credits/ALLECHEANCES.gql";
import DELETE_ECHEANCE from "../graphql/Credits/DELETE_ECHEANCE.gql";
import DATA from "../graphql/Credits/DATA.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    echeanceform: () => import("../components/EcheanceForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    echeancetype: Number,
  },
  data: () => ({
    selitem: {},
    unfilter: false,
    width: "1000px",
    orientation: "p",
    isEcheanceClosed: true,
    headers: [
      {
        text: "Credit",
        align: "start",
        value: "no_credit",
        enum: "NO_CREDIT",
        slot: "href",
        selected: true,
        index: 0,
      },
      {
        text: "Operation",
        value: "operation",
        enum: "OPERATION",
        slot: "chip",
        color: "color",
        selected: true,
        index: 2,
      },
      {
        text: "Operateur",
        align: "start",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        index: 1,
      },

      {
        text: "Date Echeance",
        value: "date_ech",
        enum: "DATE_ECH",
        selected: true,
        slot: "date",
        index: 3,
      },

      {
        text: "Date Max Execution",
        value: "date_limite",
        enum: "DATE_LIMITE",
        selected: false,
        slot: "date",
        expire: true,
        index: 3,
      },
      {
        text: "Montant",
        value: "montant",
        enum: "MONTANT",
        selected: true,
        slot: "cur",
        align: "end",
        index: 4,
      },
      {
        text: "Monnaie",
        value: "currency",
        enum: "CURRENCY",
        selected: true,
        index: 5,
      },

      {
        text: "Montant DA",
        value: "mont_dz",
        enum: "MONT_DZ",
        selected: true,
        slot: "cur",
        align: "end",
        index: 6,
      },
      {
        text: "Total remb.",
        value: "ttc_remb",
        enum: "TTC_REMB",
        selected: true,
        slot: "cur",
        align: "end",
        index: 7,
      },
      {
        text: "Agent",
        value: "user_name",
        enum: "USER_NAME",
        selected: true,
        index: 8,
      },
      {
        text: "Report",
        value: "report",
        enum: "REPORT",
        selected: true,
        index: 9,
      },

      {
        text: "Status",
        value: "status_name",
        enum: "STATUS_NAME",
        slot: "chip",
        color: "status_color",
        selected: true,
        index: 10,
      },
    ],
    ktf: 2000,
    tiers: [],
    modes: [],
    users: [],
    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    echeance: {},
    loading: false,
    title: "",
    frml: 600,
    countrys: [],
    editer: false,

    klist: 0,
    kecheance: 100,

    currencies: [],
    alert: false,
    type_alert: "info",
    message: "",
    listheaders: [],

    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    filtre: "",
    year: null,
  }),

  computed: {
    keyecheance() {
      return this.echeance.id;
    },
  },
  watch: {
    echeancetype: {
      handler() {
        this.get_data();
        this.klist++;
      },
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.Qselect = ECHEANCES;
    this.Qselectall = ALLECHEANCES;
    this.Qdelete = DELETE_ECHEANCE;
    this.year = this.$store.state.exercice;
    this.editer =
      this.$store.state.auth.includes("02005") || this.$store.state.isadmin;
    await this.get_data();
  },

  methods: {
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    echeance_valid() {
      this.$store.dispatch("Changed", true);
      this.$emit("refresh", this.editedItem);
    },
    echeance_add(item) {
      this.echeance = item;
      this.selitem = item;
    },

    async maj(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async get_data() {
      this.loading = true;

      let r = await this.requette(DATA);
      if (r) {
        this.tiers = r.alltiers;
        this.users = r.users;
      }
      //

      this.loading = false;
    },
    EcheanceOpen(item) {
      this.echeance = item;
      this.isEcheanceClosed = false;
      this.ktf++;
    },
    async EcheanceChange(item) {
      this.echeance = item;
      this.selitem = item;
      this.kecheance++;
    },
  },
};
</script>
